import React from "react";
import theme from "theme";
import { Theme, Box, Text, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"brief-history"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header position="fixed" right="0px" left="0px" z-index="1">
			<Override slot="linkBox7" color="--accentOrange" />
		</Components.Header>
		<Box height="88px" sm-height="52px" />
		<Box
			width="100%"
			height="auto"
			padding-top="120px"
			padding-bottom="64px"
			padding-left="190px"
			padding-right="190px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="40px"
			display="inline-flex"
			padding="120px 16px 64px 16px"
			sm-padding="60px 16px 24px 16px"
		>
			{"  "}
			<Box
				align-self="stretch"
				height="auto"
				flex-direction="column"
				justify-content="flex-start"
				align-items="center"
				grid-gap="16px"
				display="flex"
				margin="0px auto 0px auto"
				max-width="1348px"
				width="100%"
			>
				{"    "}
				<Text
					align-self="stretch"
					text-align="center"
					color="#080808"
					font="600 72px/82px Epilogue"
					word-wrap="break-word"
					margin="0px 0px 0px 0px"
					sm-font="600 32px/30px Epilogue"
				>
					Brief History
				</Text>
				{"    "}
				<Text
					text-align="center"
					color="#212121"
					font="400 21px Epilogue"
					line-height="32px"
					word-wrap="break-word"
					width="900px"
					max-width="100%"
					margin="0px 0px 0px 0px"
				>
					SPhotonix is a pioneer in optical storage. Since our founding, our company has relentlessly strived to push the boundaries of what is possible by developing advanced technologies to preserve information for years to come. Through innovative solutions and in-depth knowledge of optics, we have created unique products that allow you to securely store large amounts of data in a compact format.
				</Text>
				{"  "}
			</Box>
		</Box>
		<Box
			padding="0px 16px 100px 16px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			display="flex"
			width="100%"
			md-padding="24px 16px 24px 16px"
			grid-row-gap="4px"
			lg-height="auto"
		>
			<Box
				background="white"
				border="1.50px solid"
				justify-content="center"
				align-items="flex-start"
				display="flex"
				width="100%"
				border-width="1.5px"
				border-color="rgb(0,0,0,0)"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-padding="24px 24px 24px 24px"
				padding="64px 32px 64px 32px"
				grid-column-gap="50px"
				margin="0px auto 0px auto"
				max-width="1348px"
				lg-display="flex"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				lg-height="auto"
				lg-flex="0 0 auto"
				lg-grid-row-gap="24px"
			>
				{"  "}
				<Box
					justify-content="flex-start"
					align-items="center"
					grid-gap="24px"
					display="flex"
					md-display="flex"
					md-flex-direction="column"
					md-flex-wrap="no-wrap"
					md-align-items="flex-start"
					md-justify-content="center"
					lg-height="auto"
				>
					{"    "}
					<Box
						height="63px"
						border-radius="80px"
						border="2px #080808 solid"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="10px"
						display="flex"
						lg-padding="23px 32px 24px 32px"
						padding="23px 32px 24px 32px"
					>
						{"      "}
						<Text
							flex="1 1 0"
							text-align="center"
							color="#080808"
							font="500 21px Epilogue"
							text-transform="uppercase"
							line-height="21px"
							letter-spacing="1.05px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							1
						</Text>
						{"    "}
					</Box>
					{"    "}
					<Text
						color="#080808"
						font="600 28px Epilogue"
						line-height="32px"
						word-wrap="break-word"
						md-font="600 21px/28px Epilogue"
						md-width="100%"
						margin="0px 0px 0px 0px"
						max-width="480px"
					>
						Why your business needs a solution that's SPhotonix{" "}
					</Text>
					{"  "}
				</Box>
				{"  "}
				<Text
					flex="1 1 0"
					color="#212121"
					font="400 17px Epilogue"
					line-height="26px"
					word-wrap="break-word"
					margin="0px 0px 0px 0px"
					md-flex="initial"
					md-margin="16px 0px 0px 0px"
					lg-height="auto"
					lg-min-height="none"
					lg-flex="0 0 auto"
					sm-margin="0px 0px 0px 0px"
				>
					SPhotonix is a pioneer in optical storage. Since our founding, our company has relentlessly strived to push the boundaries of what is possible by developing advanced technologies to preserve information for years to come. Through innovative solutions and in-depth knowledge of optics, we have created unique products that allow you to securely store large amounts of data in a compact format.
				</Text>
			</Box>
			<Box
				background="white"
				border="1.50px solid"
				justify-content="center"
				align-items="flex-start"
				display="flex"
				width="100%"
				border-width="1.5px"
				border-color="rgb(0,0,0,0)"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-padding="24px 24px 24px 24px"
				padding="64px 32px 64px 32px"
				grid-column-gap="50px"
				margin="0px auto 0px auto"
				max-width="1348px"
				lg-display="flex"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				lg-height="auto"
				lg-grid-row-gap="24px"
			>
				{"  "}
				<Box
					justify-content="flex-start"
					align-items="center"
					grid-gap="24px"
					display="flex"
					md-display="flex"
					md-flex-direction="column"
					md-flex-wrap="no-wrap"
					md-align-items="flex-start"
					md-justify-content="center"
				>
					{"    "}
					<Box
						height="63px"
						border-radius="80px"
						border="2px #080808 solid"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="10px"
						display="flex"
						lg-padding="23px 32px 24px 32px"
						padding="23px 32px 24px 32px"
					>
						{"      "}
						<Text
							flex="1 1 0"
							text-align="center"
							color="#080808"
							font="500 21px Epilogue"
							text-transform="uppercase"
							line-height="21px"
							letter-spacing="1.05px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							2
						</Text>
						{"    "}
					</Box>
					{"    "}
					<Text
						color="#080808"
						font="600 28px Epilogue"
						line-height="32px"
						word-wrap="break-word"
						md-font="600 21px/28px Epilogue"
						md-width="100%"
						margin="0px 0px 0px 0px"
						max-width="480px"
					>
						Promising and Developing Company{" "}
					</Text>
					{"  "}
				</Box>
				{"  "}
				<Text
					flex="1 1 0"
					color="#212121"
					font="400 17px Epilogue"
					line-height="26px"
					word-wrap="break-word"
					margin="0px 0px 0px 0px"
					md-flex="initial"
					md-margin="16px 0px 0px 0px"
					lg-flex="0 0 auto"
					sm-margin="0px 0px 0px 0px"
				>
					Since its inception, SPhotononix has evolved from a small company to a recognized leader in its industry. We continuously invest in research and development to create products that meet the highest standards of quality and reliability. Today SPhotononix is a dynamic company with a global network of partners and customers.
				</Text>
			</Box>
			<Box
				background="white"
				border="1.50px solid"
				justify-content="center"
				align-items="flex-start"
				display="flex"
				width="100%"
				border-width="1.5px"
				border-color="rgb(0,0,0,0)"
				md-display="flex"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-padding="24px 24px 24px 24px"
				padding="64px 32px 64px 32px"
				grid-column-gap="50px"
				margin="0px auto 0px auto"
				max-width="1348px"
				lg-display="flex"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				lg-height="auto"
				lg-grid-row-gap="24px"
			>
				{"  "}
				<Box
					justify-content="flex-start"
					align-items="center"
					grid-gap="24px"
					display="flex"
					md-display="flex"
					md-flex-direction="column"
					md-flex-wrap="no-wrap"
					md-align-items="flex-start"
					md-justify-content="center"
				>
					{"    "}
					<Box
						height="63px"
						border-radius="80px"
						border="2px #080808 solid"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="10px"
						display="flex"
						lg-padding="23px 32px 24px 32px"
						padding="23px 32px 24px 32px"
					>
						{"      "}
						<Text
							flex="1 1 0"
							text-align="center"
							color="#080808"
							font="500 21px Epilogue"
							text-transform="uppercase"
							line-height="21px"
							letter-spacing="1.05px"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
						>
							3
						</Text>
						{"    "}
					</Box>
					{"    "}
					<Text
						color="#080808"
						font="600 28px Epilogue"
						line-height="32px"
						word-wrap="break-word"
						md-font="600 21px/28px Epilogue"
						md-width="100%"
						margin="0px 0px 0px 0px"
						max-width="480px"
					>
						Promising and Developing Company{" "}
					</Text>
					{"  "}
				</Box>
				{"  "}
				<Text
					flex="1 1 0"
					color="#212121"
					font="400 17px Epilogue"
					line-height="26px"
					word-wrap="break-word"
					margin="0px 0px 0px 0px"
					md-flex="initial"
					md-margin="16px 0px 0px 0px"
					lg-flex="0 0 auto"
					sm-margin="0px 0px 0px 0px"
				>
					SPhotonix is a company with a rich history and an innovative future. Since our founding, we have been committed to creating cutting-edge technologies for optical storage. Our solutions enable us to reliably store large amounts of data for years to come and help our customers solve their most challenging problems. Through continuous development and commitment to excellence, we have become one of the leaders in our industry.
				</Text>
			</Box>
		</Box>
		<Box min-width="100px" min-height="100px" padding="100px 16px 100px 16px" sm-padding="40px 16px 40px 16px">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				md-flex-direction="column"
				md-flex-wrap="no-wrap"
				md-display="flex"
				md-grid-row-gap="0pxa"
				grid-template-rows="1fr 1fr"
				align-items="center"
				justify-items="stretch"
				grid-gap="16px"
				md-width="100%"
				md-grid-column-gap="0px"
				md-height="fit-content"
				grid-template-columns="repeat(2, minmax(0, 1fr))"
				md-padding="100px 16px 100px 16px"
				max-width="1348px"
				margin="0px auto 0px auto"
				width="100%"
				sm-padding="0px 16px 0px 16px"
			>
				<Box
					width="100%"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px solid"
					justify-content="center"
					align-items="center"
					grid-gap="64px"
					grid-column="1 / span 2"
					border-color="rgb(0,0,0,0)"
					border-width="1.5px"
					flex-direction="row"
					flex-wrap="no-wrap"
					display="flex"
					grid-row-gap="0px"
					grid-column-gap="0px"
					height="100%"
					md-display="flex"
					md-flex-direction="column"
					md-flex-wrap="no-wrap"
					md-order="0"
					md-grid-row-gap="32px"
					md-padding="0px 0px 24px 0px"
				>
					{"  "}
					<Box
						flex="1 1 0%"
						align-self="stretch"
						padding-top="64px"
						padding-bottom="56px"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="48px"
						display="inline-flex"
						md-padding="0px 0px 0px 0px"
						height="100%"
					>
						{"    "}
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="24px"
							display="flex"
							padding="64px 64px 64px 64px"
							md-padding="0px 24px 0px 24px"
							height="100%"
						>
							{"      "}
							<Text
								align-self="stretch"
								color="#080808"
								font="700 42px Epilogue"
								line-height="50px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								md-font="700 21px/28px Epilogue"
							>
								Maximize your business potential with SPhotonix, the solution that’s as reliable as 'It Was a Very Good Year.
							</Text>
							{"      "}
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								md-font="400 15px/24px Epilogue"
							>
								SPhotonix is a pioneer in optical storage. Since our founding, our company has relentlessly strived to push the boundaries of what is possible by developing advanced technologies to preserve information for years to come.{" "}
							</Text>
							{"    "}
						</Box>
						{"  "}
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						width="100%"
						height="100%"
						flex="1 1 0%"
						md-order="-1"
					>
						<Image
							src="https://uploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13:06:17.830Z"
							object-fit="cover"
							height="100%"
							width="100%"
							srcSet="https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66d0640c995d120018f37959/images/The%20tiny%20glass%20blocks%20that%20can%20preserve%20your%20data%20for%20centuries.png?v=2024-08-31T13%3A06%3A17.830Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1100px) 100vw,100vw"
						/>
					</Box>
					{"  "}
				</Box>
				<Box
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px solid"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					grid-gap="80px"
					display="inline-flex"
					border-color="rgb(0,0,0,0)"
					border-width="1.5px"
					height="100%"
					padding="64px 64px 58px 64px"
					md-padding="24px 24px 24px 24px"
					sm-width="100%"
					md-width="100%"
				>
					{"  "}
					<Box
						align-self="stretch"
						flex-direction="column"
						justify-content="flex-start"
						align-items="flex-start"
						grid-gap="80px"
						display="flex"
						height="100%"
					>
						{"    "}
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="24px"
							display="flex"
						>
							{"      "}
							<Text
								align-self="stretch"
								color="#080808"
								font="600 28px/32px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								md-font="700 21px/28px Epilogue"
							>
								Maximize your business potential with SPhotonix, the solution that’s as reliable as 'It Was a Very Good Year.
							</Text>
							{"      "}
							<Text
								align-self="stretch"
								color="#212121"
								font="400 21px Epilogue"
								line-height="32px"
								word-wrap="break-word"
								margin="0px 0px 0px 0px"
								md-font="400 15px/24px Epilogue"
							>
								SPhotonix is a pioneer in optical storage. Since our founding, our company has relentlessly strived to push the boundaries of what is possible by developing advanced technologies to preserve information for years to come.{" "}
							</Text>
							{"    "}
						</Box>
						<Box width="100%" display="flex" flex-direction="row" flex-wrap="wrap">
							<Components.QuarklycommunityKitMarquee height="100%" width="100%" overflow-x="hidden" overflow-y="hidden">
								<Override slot="Container" grid-gap="40px" padding="0px 40px 0px 0px" />
								<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/microsoft.svg?v=2024-08-17T12:43:18.543Z" display="block" />
								<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/space-x.svg?v=2024-08-17T12:39:22.036Z" display="block" />
								<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/zeiss.svg?v=2024-08-17T12:44:19.221Z" display="block" />
								<Image src="https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/kering.svg?v=2024-08-17T12:46:11.757Z" display="block" />
							</Components.QuarklycommunityKitMarquee>
						</Box>
						{"      "}
					</Box>
				</Box>
				<Box
					padding="64px"
					background="rgba(255, 255, 255, 0.90)"
					border="1.50px solid"
					flex-direction="column"
					justify-content="space-between"
					align-items="flex-start"
					display="inline-flex"
					border-color="rgb(0,0,0,0)"
					border-width="1.5px"
					height="100%"
					md-padding="24px 24px 24px 24px"
				>
					{"  "}
					<Box
						align-self="stretch"
						flex-direction="column"
						justify-content="space-between"
						align-items="center"
						grid-gap="40px"
						display="flex"
						flex-wrap="no-wrap"
						md-display="flex"
						md-grid-row-gap="32px"
						height="100%"
					>
						{"    "}
						<Box
							align-self="stretch"
							flex-direction="column"
							justify-content="flex-start"
							align-items="flex-start"
							grid-gap="32px"
							display="flex"
						>
							{"      "}
							<Box
								align-self="stretch"
								flex-direction="column"
								justify-content="flex-start"
								align-items="flex-start"
								grid-gap="16px"
								display="flex"
							>
								{"        "}
								<Text
									align-self="stretch"
									color="#080808"
									font="600 28px/32px Epilogue"
									line-height="32px"
									word-wrap="break-word"
									margin="0px 0px 0px 0px"
									md-font="700 21px/28px Epilogue"
								>
									Maximize your business potential with SPhotonix, the solution that’s as reliable as 'It Was a Very Good Year.
								</Text>
								{"        "}
								<Text
									align-self="stretch"
									color="#212121"
									font="400 21px Epilogue"
									line-height="32px"
									word-wrap="break-word"
									margin="0px 0px 0px 0px"
									md-font="400 15px/24px Epilogue"
								>
									SPhotonix is a pioneer in optical storage. Since our founding, our company has relentlessly strived to push the boundaries of what is possible by developing advanced technologies to preserve information for years to come.{" "}
								</Text>
								{"      "}
							</Box>
							{"    "}
						</Box>
						<Button
							focus-box-shadow="none"
							align-self="stretch"
							background="#EB1E1E"
							border-radius="8px"
							text-align="center"
							color="white"
							font="600 17px Epilogue"
							line-height="26px"
							word-wrap="break-word"
							padding="16px 24px 16px 24px"
						>
							Discover Sphotonix
						</Button>
						{"  "}
					</Box>
					{"  "}
				</Box>
			</Box>
		</Box>
		<Box
			min-width="100px"
			min-height="100px"
			padding="100px 16px 100px 16px"
			display="flex"
			flex-direction="column"
			flex-wrap="no-wrap"
			grid-row-gap="48px"
			align-items="center"
			justify-content="center"
			sm-padding="40px 16px 40px 16px"
		>
			<Box
				min-width="100px"
				min-height="100px"
				sm-width="100%"
				align-items="flex-start"
				display="flex"
				flex-direction="column"
				flex-wrap="no-wrap"
				grid-row-gap="24px"
				justify-content="center"
				sm-display="flex"
				sm-grid-row-gap="16px"
				max-width="1348px"
			>
				<Text
					margin="0px 0px 0px 0px"
					font="600 64px/69px Epilogue"
					sm-width="100%"
					sm-font="600 28px/32px Epilogue"
					color="#080808"
				>
					Frequently asked questions
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="400 21px/32px Epilogue"
					sm-width="100%"
					color="#212121"
					sm-font="400 17px/26px Epilogue"
				>
					Lorem ipsum odor amet, consectetuer adipiscing elit. Platea torquent dis at aliquam pretium rutrum aptent. Senectus nibh semper rhoncus nec parturient nisl.{" "}
				</Text>
			</Box>
			<Box min-width="100px" min-height="100px" max-width="1348px">
				<Components.Collapse
					border-width="0 0 1px 0"
					border-color="#B1B1B1"
					border-radius="0px"
					padding="32px 0px 32px 0px"
					sm-padding="20px 0px 20px 0px"
				>
					<Override slot="Pre-Button">
						<Text
							color="#080808"
							font="600 28px/32px Epilogue"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="600 17px/26px Epilogue"
						>
							Is SPhotonix tools worth it?
						</Text>
					</Override>
					<Override
						slot="Title Container"
						display="flex"
						flex-direction="row"
						flex-wrap="no-wrap"
						align-items="center"
						justify-content="space-between"
					/>
					<Override
						slot="Button"
						sm-height="30px"
						sm-width="30px"
						sm-padding="0px 0px 0px 0px"
						sm-border-width="0px"
					/>
					<Text margin="0px 0px 0px 0px" font="400 21px/32px Epilogue" sm-font="400 15px/24px Epilogue">
						SPhotonix is a pioneer in optical storage. Since our founding, our company has relentlessly strived to push the boundaries of what is possible by developing advanced technologies to preserve information for years to come.{" "}
					</Text>
				</Components.Collapse>
				<Components.Collapse
					border-width="0 0 1px 0"
					border-color="#B1B1B1"
					border-radius="0px"
					padding="32px 0px 32px 0px"
					sm-padding="20px 0px 20px 0px"
				>
					<Override slot="Pre-Button">
						<Text
							color="#080808"
							font="600 28px/32px Epilogue"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="600 17px/26px Epilogue"
						>
							What makes SPhotonix stand out?
						</Text>
					</Override>
					<Override
						slot="Title Container"
						display="flex"
						flex-direction="row"
						flex-wrap="no-wrap"
						align-items="center"
						justify-content="space-between"
					/>
					<Override
						slot="Button"
						sm-height="30px"
						sm-width="30px"
						sm-padding="0 0 0 0"
						sm-border-width="0px"
					/>
					<Text margin="0px 0px 0px 0px" font="400 21px/32px Epilogue" sm-font="400 15px/24px Epilogue">
						SPhotonix is a pioneer in optical storage. Since our founding, our company has relentlessly strived to push the boundaries of what is possible by developing advanced technologies to preserve information for years to come.{" "}
					</Text>
				</Components.Collapse>
				<Components.Collapse
					border-width="0 0 1px 0"
					border-color="#B1B1B1"
					border-radius="0px"
					padding="32px 0px 32px 0px"
					sm-padding="20px 0px 20px 0px"
				>
					<Override slot="Pre-Button">
						<Text
							color="#080808"
							font="600 28px/32px Epilogue"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="600 17px/26px Epilogue"
						>
							Is SPhotonix optimized for mobile devices?
						</Text>
					</Override>
					<Override
						slot="Title Container"
						display="flex"
						flex-direction="row"
						flex-wrap="no-wrap"
						align-items="center"
						justify-content="space-between"
					/>
					<Override
						slot="Button"
						sm-height="30px"
						sm-width="30px"
						sm-padding="0 0 0 0"
						sm-border-width="0px"
					/>
					<Text margin="0px 0px 0px 0px" font="400 21px/32px Epilogue" sm-font="400 15px/24px Epilogue">
						SPhotonix is a pioneer in optical storage. Since our founding, our company has relentlessly strived to push the boundaries of what is possible by developing advanced technologies to preserve information for years to come.{" "}
					</Text>
				</Components.Collapse>
				<Components.Collapse
					border-width="0 0 1px 0"
					border-color="#B1B1B1"
					border-radius="0px"
					padding="32px 0px 32px 0px"
					sm-padding="20px 0px 20px 0px"
				>
					<Override slot="Pre-Button">
						<Text
							color="#080808"
							font="600 28px/32px Epilogue"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="600 17px/26px Epilogue"
						>
							How secure is my financial data with SPhotonix?
						</Text>
					</Override>
					<Override
						slot="Title Container"
						display="flex"
						flex-direction="row"
						flex-wrap="no-wrap"
						align-items="center"
						justify-content="space-between"
					/>
					<Override
						slot="Button"
						sm-height="30px"
						sm-width="30px"
						sm-padding="0 0 0 0"
						sm-border-width="0px"
					/>
					<Text margin="0px 0px 0px 0px" font="400 21px/32px Epilogue" sm-font="400 15px/24px Epilogue">
						SPhotonix is a pioneer in optical storage. Since our founding, our company has relentlessly strived to push the boundaries of what is possible by developing advanced technologies to preserve information for years to come.{" "}
					</Text>
				</Components.Collapse>
				<Components.Collapse
					border-width="0 0 1px 0"
					border-color="#B1B1B1"
					border-radius="0px"
					padding="32px 0px 32px 0px"
					sm-padding="20px 0px 20px 0px"
				>
					<Override slot="Pre-Button">
						<Text
							color="#080808"
							font="600 28px/32px Epilogue"
							word-wrap="break-word"
							margin="0px 0px 0px 0px"
							sm-font="600 17px/26px Epilogue"
						>
							Is SPhotonix suitable for businesses of all sizes?
						</Text>
					</Override>
					<Override
						slot="Title Container"
						display="flex"
						flex-direction="row"
						flex-wrap="no-wrap"
						align-items="center"
						justify-content="space-between"
					/>
					<Override
						slot="Button"
						sm-height="30px"
						sm-width="30px"
						sm-padding="0 0 0 0"
						sm-border-width="0px"
					/>
					<Text margin="0px 0px 0px 0px" font="400 21px/32px Epilogue" sm-font="400 15px/24px Epilogue">
						SPhotonix is a pioneer in optical storage. Since our founding, our company has relentlessly strived to push the boundaries of what is possible by developing advanced technologies to preserve information for years to come.{" "}
					</Text>
				</Components.Collapse>
			</Box>
		</Box>
		<Components.FooterWithForm />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\nbody  {\nzoom: 0.85;\n}"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
		</RawHtml>
	</Theme>;
});